#signupForm{
    @media #{$small-only} {
        padding-right: 1rem;
        padding-left: 1rem;
    }
    @media #{$medium-only} {
        padding-right: 1rem;
        padding-left: 1rem;
    }
}


.bewerTitle{
    @media #{$small-only} {
        font-size: 1.8rem;
    }  
}

#eventsContainer{
        padding-right: 2rem;
        padding-left: 2rem;
}


.map-wrap {
    position: relative;
}
.map_canvas {
    width: 100%;
    min-height: 100px;
//    margin-bottom: $column-gutter;
}
.map-marker-detail {
    width: 100%;
    height: auto;
    clear: both;
    margin-top: $column-gutter;
}
.map-categories-list {
    position: relative;
    z-index: 10;
    display: inline-block;
    padding-top: 0.5rem;
    -webkit-transform: translate3d(0,0,0);
    -webkit-transform: translateZ(0px);
}
.map-categories-list-item {
    font-family: "Avenir W01", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1;
    position: relative;
    display: inline-block;
    width: 100%;
    cursor: pointer;
    margin-right: $column-gutter;
    margin-bottom: $column-gutter/3;
    @media #{$small} {
        float: left;
        display: inline;
        width: inherit;
        margin-bottom: 0;
    }
    .cat-icon {
        line-height: 0;
        float: left;
        margin-right: $column-gutter/3;
        img {
            max-width: 22px;
        }
    }
    .cat-name {
        line-height: 0.7;
        padding-top: $column-gutter/7;
        float: left;
    }
    .cat-icon,
    .cat-name {
        line-height: 0.7;
        -webkit-opacity: 0.6;
        -moz-opacity: 0.6;
        filter:alpha(opacity=60);
    }
    &.active {
        .cat-icon,
        .cat-name {
            color: $secondary-color;
            -webkit-opacity: 1;
            -moz-opacity: 1;
            filter:alpha(opacity=100);
        }
    }
    &.all {
        .cat-icon,
        .cat-name {
            -webkit-opacity: 1;
            -moz-opacity: 1;
            filter:alpha(opacity=100);
        }
    }
}
.mapContainer .map-entries .mgnlEditorBar.component,
.mapContainer .map-person-entries .mgnlEditorBar.component {
    float: right !important;
    display: block;
}
.editmode-mapEntry-row {
    padding: 2px;
    border-bottom: 1px solid $grey;
    &:hover {
        color: $white;
        background-color: $primary-color;
    }
    img {
        height: 18px !important;
    }
}

.custom-tooltip {
    z-index: 1000;
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    // width: $column-gutter*10;
    padding: $column-gutter/2 $column-gutter/2 0 $column-gutter/2;
    min-height: $column-gutter;
    background-color: $white;
    -webkit-box-shadow: 0px 1px 21px rgba(24, 30, 28, 0.1);
    -moz-box-shadow: 0px 1px 21px rgba(24, 30, 28, 0.1);
    box-shadow: 0px 1px 21px rgba(24, 30, 28, 0.1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    filter:alpha(opacity=100);
    .address {
        margin-bottom: $paragraph-margin-bottom;
    }
}
.cat-search-area {
    position: relative;
    z-index: 900;
    display: inline-block;
    width: 100%;
    font-size: 90%;
    padding: $column-gutter/2.5 $column-gutter/2;
    background: $grey;
}
.cat-tooltip-desc {
    @extend .custom-tooltip;
    left: 0;
    margin-top: $column-gutter/3;
    &:after {
        bottom: 100%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }
    &:after {
        border-color: rgba($white, 0);
        border-bottom-color: $white;
        border-width: $column-gutter/3;
        left: $column-gutter*1.3;
        margin-left: -10px;
    }
}
.search-box {
    padding: emCalc(3px) 0;
    width: 100%;
    position:relative;
    z-index: 9;
    input {
        font-size: 90%;
        margin: 0;
        border: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        width: 100%;
        &:focus {
            border: none;
        }
    }
}
.mapPersonDetailTitle {
    display: none;
    margin-top: $column-gutter;
}
.mapPersonDetails {
    @include grid-row;
    display: none;
    margin-bottom: $column-gutter;
    padding-top: $column-gutter/2;
    padding-bottom: $column-gutter/2;
    border-top: 1px solid $grey;
    border-bottom: 1px solid $grey;
    img {
        @include grid-column(3);
        padding-left: 0;
    }
    .responsible {
        margin-bottom: $paragraph-margin-bottom;
        p {
            margin: 0;
            padding: 0;
            font-size: 95%;
        }
        &.person p:first-child {
            @extend h5;
            margin: 0;
            padding: 0;
        }
        &.contact {
            margin-bottom: 0;
        }
    }
}

.map-empty-search {
    display: none;
    margin-top: $column-gutter/2;
    color: $white;
    background-color: $secondary-color;
    padding: $column-gutter/2;
}


#grants-list {
    .row {
        .studio-link {
            &:last-child {
                .studio-item {
                    @media #{$xlarge-up} {
                        margin-left: 0.1rem;     
                    }
                    @media #{$medium-up} {
                        margin-left: 1rem;     
                    }
                }
            }
        }
    }
}
.studio-item {                        
    width: 100%;
    background: #eee;                                            
    margin-bottom: 2rem !important;

    
}                

.studio-portrait {
    padding-right: 0;

    .studio-booking-button {
        @media #{$large-up} {
            display: none;
        }
        @media #{$small-up} {
            top: 14px;
        }
        @media #{$medium-up} {
            top: 30px;
        }

        display: block;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 4px 10px 0px;
        position: absolute;
        right: 10px;
        top: 10px;
        background-color: white;
        color: black;
        font-size: 16px;
        padding: 5px 10px;
        border-radius: 40px;
    }
    @media #{$small-only} {
        padding-left: 0;
        margin-top: 1em;
    } 
}
.studio-details-preview {
    position: absolute;
    bottom: 0;
    text-decoration: none;
    color: #000000 ;
    p{
        margin-bottom: 0;
    }
}                
.studio-details-city {
    margin-top: 0.8em;
}
.studio-column {
    height: 17.2em;
    @media #{$medium-only} {    
        width: 100%;        
    }

    @media #{$medium-only} {
        height: 14.2em;
    }
    @media #{$small-only} {
        height: 14.2em;
    }
}
.no-border{
    border: none !important;
    
}
.studioImage{
    @media #{$small-only} {    
        margin-left: -1rem;
        margin-right: -1rem;
    }

    img{
        width: 350px;
        @media #{$small-only} { 
            width: 100%;
        }
    }
}



.noMarginTop{
    margin-top: 0;
    @media #{$small-only} {    
        margin-top: 7px!important;
    } 
    @media #{$medium-only} {
        margin-top: 7px!important;  
    }
}   

.imagePortrait{
    height: 270px;
    width: 270px;
    object-fit: cover;

    @media #{$small-only} {    
        width: 100%;     
    } 
    @media #{$extended-medium} {    
        width: 100%;
        padding-left: 0;   
        margin-top: 1em;  
    }
    
}

.capitalize{
    text-transform: capitalize;
}

.ui-menu-item {
    a{
        color: black;
    }
}

.hidden{
    display: none;
}

.noPadding{
    padding: 0 !important;
}

.noPaddingTop{
    padding-top: 0 !important;
}

.noPaddingRight{
    padding-right: 0 !important;
}

.noPaddingLeft{
    padding-left: 0 !important;
}

.grantSubtitle{
    font-size: 1.6rem !important;
    @media #{$medium-only} {
        font-size: 1.3rem !important;
        letter-spacing: 0.2rem !important;
    }

}
.grantDetailRows {
    p{
        margin-bottom: 0.3rem;
    }
}
.grantDetailImage{
    width: 100%
}

.fluid-width-video-wrapper{
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

.fluid-width-video-wrapper {
    iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
    }
}

.grantStudioPortrait{
    object-fit: cover;
    height: 310px;
    width: 370px;
}

.grant-info {
    padding-left: 20px;
    padding-right: 20px;
    
    .grant-text {
        padding: 20px;
        padding-left: 0px;
        padding-bottom: 0px;
    }
}

.eventRow {
    margin-bottom: 2rem;
    background: #eee;

    a div {
        padding-left: 0;
        @media #{$small-only} {
            padding-right: 0;
        }
    }
}

.noResultsMsg {
    text-align: center;
    background: #eee;
}

a.studio-link {
    color: black;

    &:hover {
        color: black;
    }
}

.eventFiltersHolder {
    @media #{$large-up} {
        padding-left: 0;
        padding-right: 0;
    }    
}

#ui-datepicker-div {
    display: none;
    background-color: #fff;
    box-shadow: 0 0.125rem 0.5rem rgba(0,0,0,0.1);
    margin-top: 0.25rem;
    border-radius: 0.5rem;
    padding: 0.5rem;
    width: 24.6rem;

    table.ui-datepicker-calendar {
        width: 23.6rem;
    }

    .ui-datepicker-calendar thead th {
        padding: 0.25rem 0;
        text-align: center;
        font-size: 0.9rem;
        font-weight: 400;
        color: #78909C;
    }

    .ui-datepicker-calendar tbody td {
        width: 2.5rem;
        text-align: center;
        padding: 0;

        a {
            display: block;
            border-radius: 0.25rem;
            line-height: 2rem;
            transition: 0.3s all;
            color: #546E7A;
            font-size: 1rem;
            text-decoration: none;

            &:hover {	
                background-color: #E0F2F1;
            }

            &.ui-state-active {
                background-color: #009688;
                color: white;
            }
        }
    }

    .ui-datepicker-header a.ui-corner-all {
        cursor: pointer;
        position: absolute;
        top: 0;
        width: 2rem;
        height: 2rem;
        margin: 0.5rem;
        border-radius: 0.25rem;
        transition: 0.3s all;

        &:hover {
            background-color: #ECEFF1;
        }
    }

    .ui-datepicker-header a.ui-datepicker-next {
    right: 0;
    background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIj48cGF0aCBmaWxsPSIjNDI0NzcwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjI4OCA2LjI5NkwzLjIwMiAyLjIxYS43MS43MSAwIDAgMSAuMDA3LS45OTljLjI4LS4yOC43MjUtLjI4Ljk5OS0uMDA3TDguODAzIDUuOGEuNjk1LjY5NSAwIDAgMSAuMjAyLjQ5Ni42OTUuNjk1IDAgMCAxLS4yMDIuNDk3bC00LjU5NSA0LjU5NWEuNzA0LjcwNCAwIDAgMS0xLS4wMDcuNzEuNzEgMCAwIDEtLjAwNi0uOTk5bDQuMDg2LTQuMDg2eiIvPjwvc3ZnPg==');
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: 50%;
	}

	.ui-datepicker-header a > span {
		display: none;
	}

	.ui-datepicker-title {
		text-align: center;
		line-height: 2rem;
		margin-bottom: 0.25rem;
		font-size: 1rem;
		font-weight: 500;
		padding-bottom: 0.25rem;
	}

	.ui-datepicker-week-col {
		color: #78909C;
		font-weight: 400;
		font-size: 0.75rem;
	}
}

.eventListImage{
	height: 385px;
    object-fit: cover;
    width: 100%;
}

.eventComponentImg{
    height: 300px;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
}

.eventDetail{
    margin-bottom: 3px;
}

.studio-border {
    outline: none;
    &:hover {
        outline: 3px solid black;
    }  
}

    
.back-link{
    font-family: 'Avenir W01',sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 900;   
    text-transform: uppercase;
    letter-spacing: 2px;
    &:hover {
        color: $black;
    }
}

h2, h3{
    line-height: 3rem;
    letter-spacing: 0.53rem !important;
}

h4, h5{
    line-height: 1.7rem !important;
}

body {
    @media #{$small-only} { 
        overflow-x: hidden; /* Hide horizontal scrollbar */
    } 
    @media #{$medium-only} { 
        overflow-x: hidden; /* Hide horizontal scrollbar */
    }      
  }

.anchor-menu-item{
    font-size: 13px;
    @media #{$large-up} { 
       width: 24.6rem !important;
       margin-right: 2rem !important;
    } 
}


// Anchor navigation

.breadcrumbs {
    font-family: "Avenir W01", sans-serif;
    font-weight: 900;
    padding-top: 2px;
    letter-spacing: rem-calc(2);
    margin-top: $paragraph-margin-bottom/2;
    margin-bottom: $paragraph-margin-bottom/2 !important;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: $paragraph-margin-bottom / 2;
    column-gap: $paragraph-margin-bottom * 1.5;

    > :before {
        margin: 0;
    }
    
    li {
        width: 25.1rem;
        text-align: left;
        background-color: #eee;
        margin-right: 0!important; 
        flex-grow: 1;

        @media #{$medium-only} {
            width: 21.2rem;               
        }
        @media #{$medium-only} and (orientation: portrait){
            width: 23.93rem;               
        }

        a{
            width: 25.1rem;
            display: inline-block; 
            padding: 0.6rem;

            &:hover {
                text-decoration: none;
                color: #000000;
            }
        }
    }
}


.freeform-form-container{
    background-color: #eee;
    margin-bottom: 2rem;

    .freeform-fieldtype-submit {
        padding-bottom: 5px!important;
    }
}

.freeform-row{
    font-family: 'Avenir W01',sans-serif !important;
    padding: 0 0.3rem !important;    
    button{
        border-radius: 1px;
        font-family: 'Avenir W01',sans-serif !important;
        clear: both !important;
        background-color: #aaa !important;
        border: 0 none !important;
        font-size: 15px !important;
        margin: 0 5px 10px 0 !important;
        padding: 0 22px !important; 
        height: 32px;
    }
    input,textarea,select,textarea::placeholder{
        font-family: "ITC Giovanni W01" !important;
    }
    .freeform-errors{
        li{
        font-family: "ITC Giovanni W01" !important;
        font-style: normal !important;
        }
    } 
}


.freeform-label{
    margin-bottom: 0 !important;
}
.freeform-form-errors, .freeform-form-success{
    border-radius: 0 !important;
    p{
        font-family: "ITC Giovanni W01" !important;
    }
}
.freeform-label.freeform-required:after, #mc_embed_signup .asterisk {
    margin-left: 0 !important;     
    color: #e85c41 !important;
    font-size: inherit !important;
    font-weight: normal;
    position: relative;
    top: 0px !important;
}

#mc_embed_signup{
    background-color: #eee !important;
    @media #{$medium-up} {
        margin-left: -1rem;             
    }
    @media #{$small-only} {
        margin-left: -1rem;
        margin-right: -1rem;
        #mc_embed_signup_scroll{
            padding-top: 1rem;
        }                
    }
    
    .helper_text, div.mce_inline_error{
        background-color: #eee !important;
    }
    .mce_inline_error{
        color: #dc3545 !important;
        font-size: 15px !important;
        margin-bottom: 0 !important;
        
    }
    input.mce_inline_error{
        background-color: #fff !important;
        border: 1px solid #ABB0B2 !important;        
    }
    input:focus{
        border: 1px solid #ABB0B2 !important;
    }
    .helper_text{
        padding: 0 !important;
    }
    input, button{
        border-radius: 1px !important;
    }
    .button{
        font-family: 'Avenir W01',sans-serif;
    }
    .mc-field-group{
        width: 100% !important;
        label{
            font-family: 'Avenir W01',sans-serif;
            margin-bottom: 0 !important;
        }
    }
    form{
        margin: 0 1rem !important;
    }
    h2{
        font-size: 1.6em !important;
    }
    div#mce-responses{
        margin-left: 0px !important;
        padding-left: 0px !important;
    }
}

.detailPageTitle{
    font-family: 'Avenir W01',sans-serif;   
    font-size: 48px;
}
.eventFiltersHolder{
    label{
        font-family: 'Avenir W01',sans-serif;
    }
    @media #{$medium-only} {
        padding-left: 0 !important;
        padding-right: 0 !important;               
    }
    @media #{$small-only} {
        padding-left: 0 !important;
        padding-right: 0 !important;               
    }
}

// Sticky header menu
#header {
    position: relative;    
}
 
#header{
    &.sticky { 
        position: sticky;
        animation: slideDown 0.20s ease-out;               
        top: 0;
        z-index: 999;
        width: 100%;
    }
}

#header{
    &.non-sticky{
         animation: slideUp 0.35s ease-out;
    }  
}
  
@keyframes slideDown {
    from {
        transform: translateY(-100%);
    }
    to {
        transform: translateY(0);
    }
}
  
  @keyframes slideUp {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-100%);
    }
  }

/* For Firefox */
html {
    scrollbar-width: thin;
    scrollbar-color: #888888 #f4f4f4;
}

/* For WebKit-based browsers (e.g., Chrome, Safari) */
body::-webkit-scrollbar {
width: 15px;
}

body::-webkit-scrollbar-thumb {
background-color: #c1bcbc;
}

body::-webkit-scrollbar-track {
background-color: #f4f4f4;
}

.studio-link-container{
    margin: 0 1rem;
    display: block;
}

.studio-details-city{
    h4{
        hyphens: manual;
        &.grant-tile-title{
             hyphens: manual;
        }
    }

    .studio-booking-button {
        display: none;

        @media #{$large-up} {
            display: block;
            position: absolute;
            background-color: white;
            color: black;
            font-size: 16px;
            padding: 5px 10px;
            border-radius: 40px;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 4px 10px 0px;
        }
    }
}
