.type-list,
.type-list-image {
    .row > .columns {
        p > a {
            text-decoration: underline;
            &:hover {
                color: $primary-color;
            }
        }
    }
}

.type-list-image {
    .row > .columns.large-3 {
        @media #{$medium-up} {
            padding-right: $column-gutter/4;
        }
    }
    .row > .columns.large-9 {
        @media #{$medium-up} {
            padding-left: $column-gutter/4;
        }
    }
}
