// Forms

form {
    h3 {
        border: none;
        @extend h4;
        &:after {
            padding: 0 !important;
        }
    }
}

.error input,
input.error,
.error textarea,
textarea.error,
select.error {
    border-color: $alert-color;
    background-color: rgba($alert-color, 0.1);
    margin-bottom: rem-calc(10);
}

.freeform-form-container {
    input, select {
        line-height: normal;
        font-size: 15px!important;  
        text-indent: 1%!important;
        padding-top: 9px!important;
    }

    select {
        padding-top: 7px!important;
    }
}

.eventsFilterHolder {
    input {
        padding-top: 7px!important;
    }
}

.days-grid {
    .day {
        &.locked {
            background-image: unset !important;
            background-color: #f9f9f9!important;
            color: #9e9e9e!important;
            font-style: italic;
            pointer-events: none;
        }
    }
}

