#stageContainer {
    background-color: $grey;
    margin-bottom: $paragraph-margin-bottom;
    .type-list-slider {
        ul.slider {
            list-style-type: none;
            padding: 0;
            margin: 0;
            li {
                padding: 0;
                margin: 0;
            }
        }
    }
}