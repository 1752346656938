// Hypens and Spacing
.wrapper {
    h1,h2,h3,h4,h5,p {
        @media #{$small-only} {
            -moz-hyphens: manual;
            -o-hyphens: manual;
            -webkit-hyphens: manual;
            -ms-hyphens: manual;
            hyphens: manual;
        }
        @media #{$small-up} and (orientation: landscape) {
            -moz-hyphens: manual;
            -o-hyphens: manual;
            -webkit-hyphens: manual;
            -ms-hyphens: manual;
            hyphens: manual;
        }
        &.nospace {
            margin-bottom: 0 !important;
            padding-bottom: 0 !important;
            &:after {
                margin-bottom: 0 !important;
                padding-bottom: 0 !important;
            }
        }
        a {
            color: inherit;
            &:hover {
                color: inherit;
            }
        }
    }

    h2,h3,h4,h5 {
        @media #{$medium-only} {
            font-size: 1.3rem;
            letter-spacing: 0.2rem;
        }
           
        letter-spacing: rem-calc(10);
        text-transform: uppercase;
        display: inline;
        &:after {
            content: "";
            display: block;
            padding-bottom: $column-gutter/2;
        }
    }

    h4,h5 {
        letter-spacing: rem-calc(5);
        line-height: rem-calc(36);
    }

    h3,h5 {
        border-top: 0;
        padding-top: 2px;
    }

    // Style mobile same as h4/h5
    @media #{$small-only} {
        h2,h3,h4,h5 {
            font-size: $h4-font-size;
            line-height: rem-calc(26);
            letter-spacing: rem-calc(5);
        }
    }
    h5{
        @media #{$small-only} {
            font-size: 1.3rem;
            letter-spacing: 0.27rem;
        }  
    } 

    h4, h5{
        // hyphens: auto;
        hyphens: manual;
    }

    .breakWord{        
        // word-wrap: break-word;
        // hyphens: auto;
        // -moz-hyphens: auto;
        // -o-hyphens: auto;
        // -webkit-hyphens: auto;
        // -ms-hyphens: auto;
        word-wrap: normal;
        hyphens: none;
        -moz-hyphens: none;
        -o-hyphens: none;
        -webkit-hyphens: none;
        -ms-hyphens: none;
    }
    /*
    @media #{$small-up} and (orientation: landscape) {
        h2,h3,h4,h5 {
            font-size: $h4-font-size;
            line-height: rem-calc(36);
            letter-spacing: rem-calc(5);
        }
    }
    */

}

#mainContentContainer {
    .type-textAndImage {
        a {
            text-decoration: underline;
            color: #000;
            &:hover {
                color: $primary-color;
            }
        }

        // h3, h4, h5{
        //     letter-spacing: 0.2rem;
        //     font-size:2.8rem;
        //     font-weight:900;
        //     line-height:3rem;
        //     border-top:0;
        //     padding-top:0;
        // }
    }
}