// Colors
$beige: #b3aea2;
$white: #ffffff;
$black: #000000;
$grey: #d6d7ce;
$grey-dark: #949699;
$blue: #3399ff;
$blue-light: #abd9f3;
$green: #bfded0;
$violet: #cecdf7;
$red: #fbd5cd;
$grey-light: #eeeeee;

// Color list
$color_names: theme-black theme-grey theme-grey-dark theme-blue theme-blue-light theme-green theme-violet theme-red theme-grey-light;
$color_vars: $black $grey $grey-dark $blue $blue-light $green $violet $red $grey-light;