@font-face {
    font-family: 'ateliermondial';
    src:url('../../fonts/ateliermondial.eot?kj0s6o');
    src:url('../../fonts/ateliermondial.eot?#iefixkj0s6o') format('embedded-opentype'),
    url('../../fonts/ateliermondial.woff?kj0s6o') format('woff'),
    url('../../fonts/ateliermondial.ttf?kj0s6o') format('truetype'),
    url('../../fonts/ateliermondial.svg?kj0s6o#ateliermondial') format('svg');
    font-weight: normal;
    font-style: normal;
}


[class^="icon-"], [class*=" icon-"] {
    font-family: 'ateliermondial';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-top:before {
    content: "\e656";
}
.icon-arrow-right:before {
    content: "\e657";
}
.icon-arrow-left:before {
    content: "\e658";
}
.icon-arrow-bottom:before {
    content: "\e659";
}
.icon-close:before {
    content: "\e61b";
}

