/* Foundation v2.1.4 http://foundation.zurb.com */
/* Artfully masterminded by ZURB  */

/* Mobile */
@media only screen and (max-width: 767px) {
    table.responsive {
        margin-bottom: 0;
        border-top: 2px solid $grey;
    }

    .pinned {
        position: absolute;
        left: 0;
        top: 0;
        background: $white;
        width: 35%;
        overflow: hidden;
        overflow-x: scroll;
        border-right: 2px solid $grey;
        border-left: 2px solid $grey;
    }
    .pinned table {
        border: none;
        border-top: 2px solid $grey;
        width: 100%;
    }
    .pinned table th,
    .pinned table td {
        white-space: nowrap;
        padding-left: rem-calc(5);
        padding-right: rem-calc(5);
    }
    .pinned table th {
        padding-top: rem-calc(10);
    }
    .pinned td:last-child {
        border-bottom: 0;
    }
    div.table-wrapper {
        position: relative;
        margin-bottom: $paragraph-margin-bottom;
        overflow: hidden;
        border-right: 2px solid $grey;
    }
    div.table-wrapper div.scrollable table {
        margin-left: 35%;
    }
    div.table-wrapper div.scrollable {
        overflow: scroll;
        overflow-y: hidden;
    }
    table.responsive td,
    table.responsive th {
        position: relative;
        white-space: nowrap;
        overflow: hidden;
        padding-left: rem-calc(5);
        padding-right: rem-calc(5);
    }
    table.responsive th {
        padding-top: rem-calc(10);
    }
    table.responsive th:first-child,
    table.responsive td:first-child,
    table.responsive td:first-child,
    table.responsive.pinned td {
        display: none;
    }
}