#signupForm {
    input[type=text], input[type=email], select {
        @media #{$large-up} {
            width: 40%;
        }
    }

    .section-title {
        display: inline-block;
        margin: 1rem 0;
    }

    a {
        text-decoration: underline;
        color: #000;
    }

    .errors {
        list-style: none;

        li {
            color: red;

        }
    }

    .has-error {
        border-color: red;
    }

    #form-input-motivationsschreiben:not(:valid),
    #form-input-nachweisUberDieZugehorigkeitZurRegion:not(:valid),
    #form-input-cvUndKunstlerInnenDossier:not(:valid) {
        opacity: 0;
        width: 100px;
        margin: 0 0 $button-margin-bottom;
        height: 1px;
    }
    
    #form-input-motivationsschreiben,
    #form-input-nachweisUberDieZugehorigkeitZurRegion,
    #form-input-cvUndKunstlerInnenDossier {
        width: 100%;
        display: block;
        margin-top: 10px;

        &::file-selector-button {
            display: none;
        }
    }
    
    .ff-fieldtype-radio_group {
        margin: 1rem 0;
        
        .form-check {
            display: inline-block;
        }
    }
    
    label[for=form-input-motivationsschreiben],
    label[for=form-input-nachweisUberDieZugehorigkeitZurRegion],
    label[for=form-input-cvUndKunstlerInnenDossier] {
        width: auto;
        display: inline-block;
        background-color: $bg;
        color: $button-font-color;
        border-style: $button-border-style;
        border-width: $button-border-width;
        cursor: $cursor-pointer-value;
        line-height: normal;
        //margin: 0 0 $button-margin-bottom;
        position: relative;
        text-decoration: none;
        text-align: $button-font-align;
        text-transform: uppercase;
        padding: 0.5em 1.1em;
        font-weight: normal;
        cursor: pointer;
        text-rendering: optimizeLegibility;
        font-family: "Avenir W01",sans-serif;
        font-weight: bold;
        transition: background-color 300ms ease-out;
    
        &:hover,
        &:focus {
            background: #007af5;
        }
    }
}
