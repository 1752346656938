@each $name in $color_names {
    $i: index($color_names, $name);
    .row,
    .panel {
        &.#{$name} {
            $bg-lightness: lightness(nth($color_vars, $i));
            background-color: nth($color_vars, $i);
            h2,h4 {
                @if $bg-lightness < 78% {
                    color: $white;
                }
            }
            hr {
                border-color: $white;
            }
            .text-section.dividing {
                padding-top: $paragraph-margin-bottom;
            }
            @if $bg-lightness < 78% {
                .text-section.dividing {
                    border-top: 1px solid transparentize($white, 0.2);
                }
                a:hover {
                    color: $grey !important;
                }
            }
        }
    }
}

// Special black version

.row,
.panel {
    &.theme-black {
        h2,h3,h4,h5,p {
            color: $white;
        }
        a {
            color: $white;
            &:hover {
                color: $blue !important;
            }
        }
        p > a {
            text-decoration: underline;
        }
    }
}

// Panel basic settings

.panel {
    background-color: transparent;
    &.inline-row {
        padding: $column-gutter/2 0 0 0;
        margin: 0 $column-gutter/2;
    }
    &.inline {
        padding-bottom: 0;
        margin-bottom: $column-gutter;
        .image-only {
            margin-bottom: 0;
        }
        .imageHolder {
            &.image_top {
                @media #{$medium-up} {
                    margin-top: -$column-gutter/2;
                    margin-left: -$column-gutter/2;
                    margin-right: -$column-gutter/2;
                }
            }
            &.image_right {
                @media #{$medium-up} {
                    margin-top: -$column-gutter/2;
                    margin-right: -$column-gutter/2;
                    margin-bottom: 0;
                }
            }
            &.image_left {
                @media #{$medium-up} {
                    margin-top: -$column-gutter/2;
                    margin-left: -$column-gutter/2;
                    margin-bottom: 0;
                }
            }
        }
    }
}