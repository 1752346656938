#realtimefilter_empty,
#realtimefilter_submit {
    display: none;
}

#realtimefilter_empty p,
.realtimefilter_more {
    font-family: "Avenir W01", sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    font-size: $h5-font-size;
    font-style: oblique;
    letter-spacing: rem-calc(5);
    line-height: rem-calc(36);    
    color: $black;
    padding-top: 2px;
    display: block;
    background-color: $grey;
    text-align: center;
    padding: $column-gutter/2;
    margin-bottom: $column-gutter/2;
}

.realtimefilter_loading {
    width: 50px;
    height: 50px;
    margin: 0 auto;
    background-image: url('../../img/misc/loader.gif');
    @media screen and (-webkit-min-device-pixel-ratio: 1.3), screen and (min--moz-device-pixel-ratio: 1.3), screen and (-o-min-device-pixel-ratio: 2 / 1), screen and (min-device-pixel-ratio: 1.3), screen and (min-resolution: 192dpi), screen and (min-resolution: 2dppx) {
        background-image: url('../../img/misc/loader.gif');
    }
    background-repeat: no-repeat;
    background-size: 24px;
}

#realtimefilter_form {
    label {
        font-family: "Avenir W01", sans-serif;
        font-weight: 400;
    }

    input, select {
        font-size: 15px!important;
        text-indent: 1%!important;
        padding-top: 9px!important;
    }

    select {
        padding-top: 7px!important;
    }
}

#realtimefilter_output {
    .panel {
        a {
            display: block;
            color: $body-font-color;
            &:hover {
                color: inherit;
            }
        }
        border: 2px solid $white;
        &:hover {
            border: 2px solid $black;
        }
        .realtimefilter_image {
            overflow: hidden;
            height: 180px;
            background-image: url('../../img/misc/default.png');
            @media screen and (-webkit-min-device-pixel-ratio: 1.3), screen and (min--moz-device-pixel-ratio: 1.3), screen and (-o-min-device-pixel-ratio: 2 / 1), screen and (min-device-pixel-ratio: 1.3), screen and (min-resolution: 192dpi), screen and (min-resolution: 2dppx) {
                background-image: url('../../img/misc/default@2x.png');
            }
            background-position: center;
            background-color: $blue-light;
            background-repeat: no-repeat;
            -webkit-background-size: cover;
            -moz-background-size: cover;
            -o-background-size: cover;
            background-size: cover;
            margin-top: -$column-gutter/2;
            margin-left: -$column-gutter/2;
            margin-right: -$column-gutter/2;
            margin-bottom: $column-gutter/2;
        }
        .realtimefilter_meta {
            .label {
                margin-bottom: 0;
                background-color: $beige;
            }
        }
    }
}

// Autocomplete Plugin

.ui-helper-hidden-accessible {
    display: none;
}

.ui-autocomplete {
    max-height: 200px;
    overflow-y: auto;
    /* prevent horizontal scrollbar */
    overflow-x: hidden;
    /*max-width: 204px;*/
    text-align: left;
    font-family: inherit;
    font-size: inherit;
    position: absolute;
    top: 0;
    left: 0;
    cursor: default;
}

.ui-menu {
    list-style: none;
    padding: 0;
    margin: 0;
    display: block;
    outline: none;
}

.ui-widget-content {
    border-left: 1px solid $grey-dark;
    border-right: 1px solid $grey-dark;
    border-bottom: 1px solid $grey-dark;
    border-top: none;
    background-color: $white;
    background-color: rgba(255, 255, 255, 0.9);
}

.ui-menu-item {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.ui-state-focus {
    border: none !important;
    background: $blue-light !important;
    background-image: none !important;
}

.ui-menu .ui-menu-item {
    position: relative;
    margin: 0;
    padding: rem-calc(5 5 0 5);
    cursor: pointer;
    min-height: 0;
    list-style-image: none;
}