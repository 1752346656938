// Spacer
.spacerAfter0,
.spacerAfter1,
.spacerAfter2,
.spacerAfter3,
.spacerAfter4,
.spacerAfter5,
.spacerAfter10 {
    clear: both;
    @include clearfix();
}

.spacerAfter1 {
    height: $paragraph-margin-bottom !important;
}

.spacerAfter2 {
    height: $paragraph-margin-bottom*2 !important;
}

.spacerAfter3 {
    height: $paragraph-margin-bottom*3 !important;
}

.spacerAfter4 {
    height: $paragraph-margin-bottom*4 !important;
}

.spacerAfter5 {
    height: $paragraph-margin-bottom*5 !important;
}

.spacerAfter10 {
    height: $paragraph-margin-bottom*10 !important;
}

.spacerTitle1,
.spacerTitle2 {
    display: block;
}

.spacerTitle1 {
    height: emCalc(25px);
}

.spacerTitle2 {
    height: emCalc(62px);
}

.dividingLine {
    border-top: 2px solid $grey;
    padding-top: $column-gutter/2;
}

.center {
    text-align: center;
}

// Tables

#mainContentContainer {
    background-image: linear-gradient(0deg, #cdcdcd80, #fff 12rem);
    padding-bottom: 4rem;
    table {
        width: 100%;
        caption {
            text-align: left;
            @extend h2;
        }
        th {
            border-bottom: 4px solid $black;
        }
        td {
            border-bottom: 2px solid $grey;
            vertical-align: top;
            &:last-child {
                padding-right: 0;
            }
        }
    }
}

#mainContentContainer {
    table{
        border: transparent;
    }
}

#mainContentContainer {
    table{ 
        tr {
            td {
                &:first-child {
                    border-right: none;
                }
            }
        }
    }
}

#mainContentContainer {
    table{ 
        tr {            
            &:last-child {
                border-bottom: transparent;
            }            
        }
    }
}

/* Inline images */

.imageHolder {
    position: relative;
    margin-bottom: $paragraph-margin-bottom;
    img {
        position: relative;
    }
    .caption {
        @media #{$medium-up} {
            position: absolute;
            top: 0;
            right: $column-gutter/2;
        }
        text-align: right;
        font-family: "ITC Giovanni W01", serif;
        font-size: 15px;
        line-height: 20px;
        background-color: $white;
        color: $black;
        border-top: 2px solid $black;
        padding: rem-calc(4 8);
    }
    &.image_left,
    &.image_right {
        width: 50%;
        @media #{$small-only} {
            width: 100%;
            float: none !important;
        }
    }
    &.image_left {
        float: left;
        margin-right: $paragraph-margin-bottom;
        @media #{$small-only} {
            margin-right: 0;
        }
    }
    &.image_right {
        float: right;
        margin-left: $paragraph-margin-bottom;
        @media #{$small-only} {
            margin-left: 0;
        }
    }
    &.image_top {
        margin-bottom: $column-gutter/2;
        &.no-content {
            margin-bottom: $paragraph-margin-bottom*2;
        }
    }
}


/* Text/Image */

.type-textAndImage {
    @include clearfix;
    &.center {
        ul, ol {
            list-style-type: none;
        }
    }

    h2, h5 {
        @media #{$small-up} {
            //word-break: break-word;
            word-break: normal;
            hyphenate-limit-chars: 10 3 4;
            hyphens: manual!important;
        }
    }
}

.normal-link {
    font-weight: normal!important;
}

.inline-link {
    display: inline-block!important;
}

.large-1,
.large-2,
.large-3,
.large-4,
.large-5 {
    p {
        padding-right: 0;
    }
}

/* Generated Content Wrapper */

.generatedContentWrapper {
    .type-textAndImage {
        border-bottom: 1px solid $grey;
        h2 {
            display: block;
            margin: 0 !important;
            padding: 0 !important;
            font-size: $h4-font-size;
            letter-spacing: rem-calc(5);
            cursor: pointer;
            &:after {
                display: none;
            }
        }
        .generation-date {
            margin-bottom: $paragraph-margin-bottom;
            cursor: pointer;
        }
        a,
        p {
            display: none;
        }
    }
    .spacerAfter2 {
        display: none !important;
    }
    .panel {
        margin-bottom: 0;
    }
}

/* Video & iFrame */

.flex-video {
    margin-bottom: $paragraph-margin-bottom;
}

iframe {
    border: none;
    padding: 0;
    margin-bottom: $paragraph-margin-bottom;
    display: block;
    width: 100%;
    overflow: hidden;
}

/* Fancybox */

.fancybox-title-inside-wrap {
    font-size: inherit !important;
    font-family: inherit !important;
    line-height: 1.6 !important;
    padding: 10px;
}

.fancybox-skin {
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
}

.fancybox-close {
    top: 10px !important;
    right: 10px !important;
}

// Vimeo modal player
.modalVimeo {
    display: none;
    @media #{$medium-only} {
        width: 80% !important;
        max-width: 80% !important;
    }
    @media #{$large-up} {
        width: 50% !important;
        max-width: 50% !important;
    }
  }
  
.mm {
    height: 0;
    padding-bottom: 56.25%; 
    position: relative;
    overflow: hidden;
}
.mm iframe,
.mm object,
.mm embed,
.mm video {
    border: 0;
    width: 100% !important;
    height: 100% !important;
    max-width: none;
    position: absolute;
    top: 0;
    left: 0;
}

.vimeoPlayOverlay{
    width: 60px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.vimeoPlayHolder{
    position: relative;
}