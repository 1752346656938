/* Pagination */

.pagination {
    display: table;
    margin-bottom: $paragraph-margin-bottom/2;
    &.right {
        float: left !important;
        @media #{$small} {
            float: left !important;
            margin-left: $column-gutter;
        }
    }
    &.left {
        @extend h4;
        .arrow {
            font-weight: 700;
            a {
                color: $secondary-color;
                display: block;
                padding-left: 0;
                padding-top: rem-calc(4);
                background: none;
                &:hover {
                    color: $primary-color;
                }
            }
        }
    }
    li {
        margin-bottom: $paragraph-margin-bottom/3;
        a {
            display: block;
            text-decoration: none;
            &.rss,
            &.today_link,
            &.calendar-subscription {
                margin-top: rem-calc(3);
                padding-left: rem-calc(25);
                background-repeat: no-repeat;
                background-position: left center;
                margin-right: rem-calc(5);
                &:hover {
                    border-radius: 10px;
                }
            }
            &.rss {
                background-image: url('../../img/misc/rss.png');
                @media screen and (-webkit-min-device-pixel-ratio: 1.3), screen and (min--moz-device-pixel-ratio: 1.3), screen and (-o-min-device-pixel-ratio: 2 / 1), screen and (min-device-pixel-ratio: 1.3), screen and (min-resolution: 192dpi), screen and (min-resolution: 2dppx) {
                    background-image: url('../../img/misc/rss@2x.png');
                }
                background-size: 20px;
            }
            &.today_link {
                background-image: url('../../img/misc/today.png');
                @media screen and (-webkit-min-device-pixel-ratio: 1.3), screen and (min--moz-device-pixel-ratio: 1.3), screen and (-o-min-device-pixel-ratio: 2 / 1), screen and (min-device-pixel-ratio: 1.3), screen and (min-resolution: 192dpi), screen and (min-resolution: 2dppx) {
                    background-image: url('../../img/misc/today@2x.png');
                }
                background-size: 20px;
            }
            &.calendar-subscription {
                background-image: url('../../img/misc/ics.png');
                @media screen and (-webkit-min-device-pixel-ratio: 1.3), screen and (min--moz-device-pixel-ratio: 1.3), screen and (-o-min-device-pixel-ratio: 2 / 1), screen and (min-device-pixel-ratio: 1.3), screen and (min-resolution: 192dpi), screen and (min-resolution: 2dppx) {
                    background-image: url('../../img/misc/ics@2x.png');
                }
                background-size: 20px;
            }
        }
    }
    &:after {
        clear: both;
    }
}