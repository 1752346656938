#header {
    background-color: $primary-color;
}

#menu {
    padding: $column-gutter/3 0;
    @include clearfix;
}

#navigation,
#meta {
    @media #{$medium-up} {
        display: inline-block;
    }
    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        line-height: 1;
        display: none;
        @media #{$medium-up} {
            display: inline-block;
        }
        li {
            display: inline-block;
            @media #{$small-only} {
                display: block;
                border-bottom: 1px solid $white;
            }
            text-transform: uppercase;
            font-family: "Avenir W01", sans-serif;
            font-weight: 900;
            font-size: 13px;
            line-height: 1;
            font-style: normal;
            letter-spacing: rem-calc(2);
            @media #{$small-only} {
                display: block;
            }
            a {
                display: block;
                @media #{$small-only} {
                    // border-bottom: 1px solid $white;
                    padding: 0.5rem 0;
                    display: inline-block !important;
                    width: 88%;
                }
                &:hover,
                &.active {
                    color: $black;
                }
            }
            .active {
                color: $black;
            }
        }
    }
}
.arrowHolder{
    display: inline-block;
    width: 18%;
    position: absolute;
    padding-right: 5px;
    text-align: right;
    height: 2rem;
    right: 0;
}

#navigation {
    @media #{$medium-up} {
        float: left;
    }
    @media #{$small-only} {
        margin-left: -1rem;
        margin-right: -1rem;
        
    }
    ul {
        @media #{$small-only} {
            padding-left: 1rem;
            padding-right: 1rem;
            &:first-child {
                margin-top: $column-gutter/4;
                border-top: 1px solid transparentize($white, 0.8);
            }
        }
        li {
            display: inline-block;
            position: relative;
            z-index:100;
            margin-right: $column-gutter/2;
            @media #{$small-only} {
                display: block !important;
                margin: 0 !important;
            }
            a {
                display: block;
                color: $white;
                letter-spacing: 2px;
            }
            &:last-child {
                margin-right: 0;
            }
            ul.sub-nav {
                display: none;
                margin: 0;
                padding: 0;
                @media #{$medium-up} {
                    position: absolute;
                    padding-top: $column-gutter/2.6;
                    // top: $column-gutter/4;
                    left: 0;
                }
                @media #{$small-only} {
                    display: block;
                    padding-top: 0;
                    padding-left: $column-gutter/2;
                }
                li {
                    display: block;

                    a {
                        @media #{$medium-up} {
                            display: inline-block;
                            white-space: nowrap;
                            line-height: 24px;
                            color: $grey-dark;
                            background-color: $white;
                            border-top: 2px solid $grey-dark;
                        }
                    }
                    a.active,
                    a:hover {
                        color: $black;
                        @media #{$medium-up} {
                            border-top: 2px solid $black;
                        }
                    }
                    @media #{$medium-up} {
                        &:first-child {
                            a {
                                border-color: transparent;
                            }
                        }
                    }
                }
                .active {
                    color: $black;
                    a {
                        color: $black;
                    }
                }
            }
            &:hover > ul {
                @media #{$medium-up} {
                    display: block;
                }
            }
        }
    }
}

#meta {
    @media #{$medium-up} {
        float: right;
    }
    ul {
        @media #{$medium-up} {
            float: right;
        }
        li {
            font-weight: 900;
            @media #{$medium-up} {
                float: left;

                &:not(:first-child){
                    border-left:2px solid $white;
                }
                a {
                    padding-left: $column-gutter/3;
                    padding-right: $column-gutter/3 - rem-calc(2);
                }
            }
            a {
                color: $white;
            }
        }
    }
}

#touch-menu {
    display: none;
    @media #{$small-only} {
        display: block;
        width: 65%;
        float: right;
        height: 15px;
    }
    position: relative;
    color: $white;
    font-family: "Avenir W01", sans-serif;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 5px;
    &::after {
        content:"";
        position: absolute;
        right: 3px;
        display: block;
        width: 16px;
        top: -8px;
        height: 0;
        box-shadow: 0 10px 0 1px $white, 0 16px 0 1px $white, 0 22px 0 1px $white;
    }
    &:hover {
        color: $black;
        &::after {
            box-shadow: 0 10px 0 1px $black, 0 16px 0 1px $black, 0 22px 0 1px $black;
        }
    }
}

.mobileLang{
    display: none;
    @media #{$small-only} {
        display: inline-block;
        width: 35%;        
    }
    a{
        color: $white;
        font-family: "Avenir W01", sans-serif;
        font-size: 13px;
        font-weight:900;
    }
    a.active{
        color: $black;
    }

}

#stickyAnchors{
    @media #{$small-only} {
        display: none;              
    }
}

.mobileLang a:nth-child(1){
    padding-right: 10px;
    border-right: 2px solid $white;
}
.mobileLang a:nth-child(2){
    padding-right: 10px;
    padding-left: 8px;
    border-right: 2px solid $white;
}
.mobileLang a:nth-child(3){
    padding-left: 8px;
}

#navigation{
    ul{
        li:has(.mobileAnchors[style*="display: block"]){
            border-bottom: 0;
        }
    }

    ul.mobileAnchors{
        display: none;
        width: 110%;
        right: 1rem;
        position: relative;
        padding-left: 1rem;
        background-color: $white;       
        li{
            border-bottom: 1px solid #a3a3a3;
        }
        a{
            color: #a3a3a3;
        }
    }

    ul.mobileAnchors li:last-child{
        border-bottom: 0;
    }

    
}
#navigation{
    ul.mobileAnchors{
        @media #{$medium-up} {
            display: none !important;
        }
        li.previous-active{
            border-bottom: 0;
        }
        li.mobile-anchor-menu-item.active{
            @media #{$small-only} {        
                background-color: $black;
                margin-left: -1rem !important;
                margin-right: -1rem !important;
                padding-left: 1rem;            
            }
            a{
                color: $white;
            }
        }        
    }
}

a.active + .mobileAnchors{
    display: inline-block !important;
}


#brand {
    text-align: center;
    padding: $column-gutter/1.5;
    img {
        width: 115px;
        height: auto;
        @media #{$large-up} {
            width: 140px;
        }
        @media #{$small-only} {
            width: 155px;
        }
    }
}

  .anchorArrow {
    display: none;
         @media #{$small-only} {
            border: solid black;
            border-width: 0 2px 2px 0;
            display: inline-block;
            padding: 5px;
            transition: transform 0.3s ease; /* Add transition for smooth rotation */
            -webkit-transition: transform 0.3s ease;                
        }
  }

  .up {
    transform: rotate(225deg);
    -webkit-transform: rotate(225deg);
  }

  .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

  .hasUpChild{
    padding-top: 11px;
  }

  .hasDownChild{
    padding-top: 4px;
  }


  #navigation{
    ul{
        li{
            a.active{
                @media #{$small-only} {
                    color: white;
                    background-color: black;
                    margin-left: -1rem;
                    padding-left: 1rem;                    
                    border-bottom: none;
                    height: 2rem;
                    line-height: normal;
                }
            }
            a.active > ul.mobileAnchors > li.active{
                background-color: red;
            }
            a.active:not(:has(~ span.arrowHolder)){
                @media #{$small-only} {
                    width: 109%;
                }
            }
            a.active:has(~ span.arrowHolder){
                @media #{$small-only} {
                    width: calc(88% + 1rem);
                }
            }
         
            a.active + .arrowHolder{
                @media #{$small-only} {
                    background-color: black;
                    margin-right: -1rem;
                    height: 2rem;
                    padding-right: calc(5px + 1rem);
                    .up, .down{
                        border-color: $white;
                        // margin-right: 0.15rem;
                    }
                }
            }
            a.activeWithAnchor{
                color: $black;
            }
        }
    }
  }

  