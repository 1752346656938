/* Calendar config */

$typ0-color: $blue-light;
$typ1-color: $blue-light;
$typ2-color: $blue-light;
$typ3-color: $blue-light;
$typ4-color: $blue-light;
$typ5-color: $blue-light;
$typ6-color: $blue-light;
@mixin eventcalendar-typ-theme($typ-color) {
    background-color: $typ-color;
}

/* calendar */

.type-list-calendar {
    margin-bottom: $paragraph-margin-bottom;
    table {
        border: none;
        border-collapse: separate;
        background-color: tint($black, 90%);
        tr {
            th {
                background-color: $primary-color;
                border: 2px solid $white;
                padding: rem-calc(5 10);
                &:first-child {
                    border-left: none;
                }
                &:last-child {
                    border-right: none;
                }
            }
            td {
                vertical-align: top;
                padding: 0;
                border: 2px solid $white;
                height: 100px;
                font-size: 85%;
                width: 14.28571428571429%;
                &:first-child {
                    border-left: none;
                }
                &:last-child {
                    border-right: none;
                }
                .day {
                    line-height: 1;
                    padding: emCalc(8px) emCalc(10px);
                    display: block;
                }
                &.today {
                    color: $white;
                    background-color: $black;
                }
                ul {
                    margin: 0;
                    padding: 0;
                    display: block;
                    li {
                        display: block;
                        list-style-type: none;
                        @include eventcalendar-typ-theme($typ0-color);
                        border-bottom: 1px solid $white;
                        &:last-child {
                            border: none;
                        }
                        a {
                            padding: emCalc(4px) emCalc(10px);
                            display: block;
                            color: $white;
                            text-decoration: none;
                            &:hover {
                                color: $black;
                            }
                        }
                    }
                }
            }
        }
    }
    .typ1 { @include eventcalendar-typ-theme($typ1-color); }
    .typ2 { @include eventcalendar-typ-theme($typ2-color); }
    .typ3 { @include eventcalendar-typ-theme($typ3-color); }
    .typ4 { @include eventcalendar-typ-theme($typ4-color); }
    .typ5 { @include eventcalendar-typ-theme($typ5-color); }
    .typ6 { @include eventcalendar-typ-theme($typ6-color); }
}



.type-list,
.type-list-calendar {
    .pagination {
        &.left {
            margin-right: $column-gutter;
        }
        &.right {
            padding-top: emCalc(3px);
            float: left !important;
            margin-left: 0;
            li {
                margin-left: 0;
            }
        }
        .month_year {
            font-size: emCalc(26px);
            line-height: 1.2;
        }
        .arrow {
            margin: 0;
            padding: 0;
            line-height: 1;
            background: none !important;
            &:hover {
                background: none !important;
            }
        }
    }
}

.type-list .eventcalendar {
    margin: 0;
    padding: 0;
    display: block;
    list-style-type: none;
    width: 100%;
    margin-bottom: $paragraph-margin-bottom;
    li {
        display: table;
        width: 100%;
        background-color: $grey;
        margin-bottom: $column-gutter/2;
        a {
            display: table;
            width: 100%;
            height: 100%;
        }
        .date-circle {
            display: table-cell;
            margin-right: $column-gutter/2;
            font-family: "Avenir W01", sans-serif;
            font-weight: 900;
            color: $blue;
            float: left;
            vertical-align: center;
            text-align: center;
            text-transform: uppercase;
            width: $column-gutter*4;
            @media #{$small-only} {
                width: $column-gutter*2;
                min-height: $column-gutter*2;
            }
            height: 100%;
            @include eventcalendar-typ-theme($typ0-color);
            .date {
                font-size: rem-calc(140);
                line-height: rem-calc(105);
                @media #{$small-only} {
                    font-size: rem-calc(70);
                    line-height: rem-calc(50);
                }
                padding: $column-gutter/3 0 $column-gutter/4 0;
                margin: 0;
                display: block;
            }
            .month,
            .year {
                font-size: rem-calc(20);
                line-height: rem-calc(20);
                letter-spacing: rem-calc(5);
                @media #{$small-only} {
                    font-size: rem-calc(14);
                    line-height: rem-calc(14);
                    letter-spacing: rem-calc(2);
                }
                display: block;
                margin: 0;
                padding: 0;
            }
            .year {
                padding-bottom: $column-gutter/3;
            }
        }
        &:hover .date-circle,
        .today .date-circle {
            color: $blue-light;
            background-color: $blue;
        }
    }
//    .typ1 .date-circle { @include eventcalendar-typ-theme($typ1-color); }
//    .typ2 .date-circle { @include eventcalendar-typ-theme($typ2-color); }
//    .typ3 .date-circle { @include eventcalendar-typ-theme($typ3-color); }
//    .typ4 .date-circle { @include eventcalendar-typ-theme($typ4-color); }
//    .typ5 .date-circle { @include eventcalendar-typ-theme($typ5-color); }
//    .typ6 .date-circle { @include eventcalendar-typ-theme($typ6-color); }
    .entry-detail {
        display: table-cell;
        vertical-align: top;
        width: 100%;
        padding: $column-gutter/3 0;
        font-size: rem-calc(14);
        .lead,
        .calendar {
            font-family: $header-font-family;
            font-weight: $header-font-weight;
            color: $body-font-color;
            display: block;
            margin: 0;
            font-size: rem-calc(14);
            text-transform: uppercase;
            letter-spacing: rem-calc(2);
        }
        .lead {
            padding-bottom: $column-gutter/3;
        }
        .title {
            @extend h5;
            margin: 0;
            padding: 0;
            &:after {
                padding-bottom: $column-gutter/4;
            }
        }
        .full-date {
            display: block;
            font-weight: bold;
            color: $body-font-color;
        }
    }

}

.eventcalendar-detail {
    padding: $column-gutter;
    padding-right: $column-gutter*2;
    max-width: ($row-width)-($column-gutter);
    a {
        text-decoration: underline;
        &:hover {
            color: $black;
        }
    }
}

.form-element.event {
    margin-bottom: $paragraph-margin-bottom;
    .eventcalendar-detail {
        padding: $paragraph-margin-bottom;
        max-width: inherit;
        background-color: $grey;
    }
}

.container.amp-plugin .calendars .calendar>.header .month-name {
    padding-bottom: 20px;
}