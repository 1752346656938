#footer {
    background-color: $white;
    p,
    h2,
    h3,
    h4,
    h5 {
        font-family: "Avenir W01", sans-serif;
        color: $black;
    }
    .panel.inline-row:first-of-type {
        @media #{$medium-up} {
            padding-top: 0 !important;
        }
        .columns {
            @media #{$medium-up} {
                padding-top: $column-gutter/2;
            }
        }
    }
    .panel.inline-row {
        @media #{$small-only} {
            padding: 1em;
            margin: auto 0;
        }
        a {
            color: $black;
            text-decoration: none;
            word-wrap: break-word;
            
            &:hover {
                color: $primary-color !important;
            }
        }
        .columns {
            @media #{$large-up} {
                border-width: 1px 0 1px 1px;
                border-style: solid;
                -webkit-border-image: -webkit-gradient(linear, 0 0, 0 100%, from(white), to(rgba(255, 255, 255, 0))) 1 100%;
                -webkit-border-image: -webkit-linear-gradient(white, rgba(255, 255, 255, 0)) 1 100%;
                -moz-border-image: -moz-linear-gradient(white, rgba(255, 255, 255, 0)) 1 100%;
                -o-border-image: -o-linear-gradient(white, rgba(255, 255, 255, 0)) 1 100%;
                border-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0)) 1 100%;
                &:last-child {
                    border-width: 0 1px 1px 1px;
                }
            }
        }
    }

    img {
        @media #{$small-only} {
            max-width: 86%;
            margin: 0 auto;
            display: inherit;
            margin-bottom: 2rem
        }
    }
    p, a, strong{
        font-size: 13px;
        line-height: 18px;
    }
    

}
#footer .footerRow:nth-child(1) {
    @media #{$small-only} {
        margin-bottom: -6rem;
    } 
}
#footer .footerRow:nth-child(2) {
    max-width: 100%;
    background-color: #fff;
    // background-image: linear-gradient(0deg,#e9e9e9,#fff 2rem);
    height: 3rem;
    .row{
        margin: 0 auto;
    }
}

#footer .row.content:nth-child(1) {
    .columns:nth-child(5) {
        @media #{$medium-up} {
            width: 4rem;
        }
    }
}    
#footer .row.content:nth-child(1) {
    .columns:nth-child(6) {
        @media #{$medium-only} {
            width: 13rem;
        }
        @media #{$large-up} {
            width: 16rem;
        }
    }
}
#footer .row .row, #footer .row .panel.inline-row{
    margin: 0;
}

#footer .row:nth-child(3) .row{
    margin-top: 1rem;    
    .columns{
        @media #{$medium-up} {        
         padding-bottom: 2rem;
        }
    }
    @media #{$medium-up} {
        .columns:nth-child(1){               
            margin-top: 22px;     
        }
        .columns:nth-child(2){        
            margin-top: 14px;        
        }
        .columns:nth-child(3){        
            margin-top: 24px;
        }
        .columns:nth-child(4){        
            margin-top: 19px;        
        }
        .columns:nth-child(5){
            margin-top: 13px;
        }
        .columns:nth-child(6){
            margin-top: 0px;
        }
    }
}

#footer {
    .footerRow:nth-child(1){
        @media #{$small-only} {
            .columns{
                padding-left: 0;
                padding-right: 0;
            }
            .columns:nth-child(-n+5){
                width: 50%;
                clear: both;
            }
            .columns:nth-child(6){
                width: 50%;
                position: absolute;
                right: -1rem;
            }
        }
    }
    .footerRow:nth-child(3){
        @media #{$small-only} {
            div.row{
                .columns{
                    width: 50%;
                }
                .columns:nth-child(3){
                    clear: both;
                }
            }
        }
    }
    .footerRow:nth-child(4){
        @media #{$small-only} {
            .columns:nth-child(-n+2){
                padding-left: 0;
                padding-right: 0;
                width: 45%;
                margin-bottom: -3rem;                
            }
            .columns:nth-child(3){
                clear: both;            
            }
            .columns:nth-child(n+1){
                padding-left: 1rem;                                
            }     
            .columns:nth-child(n+3){
                padding-left: 1rem;
                padding-right: 0;
                width: 33.33%;                
            }            
        }
        @media #{$medium-up} {
            .columns:nth-child(n+3){
                padding-bottom: 1rem;                               
            } 
        }
    }

}

