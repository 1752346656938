/* Download links */
ul.downloads {
    margin-left: 0;
    border-top: none;
    padding: 0;
    li:before {
        content: none !important;
        margin-right: 0;
    }
    li {
        &.download {
            list-style-type: none;
            background: none;
            padding: 0;
            padding-left: $column-gutter;
            background-image: url('../../img/filetype-icons/generic.png');
            background-position: left 2px;
            background-repeat: no-repeat;
            background-size: 16px;
            @media screen and (-webkit-min-device-pixel-ratio: 1.3), screen and (min--moz-device-pixel-ratio: 1.3), screen and (-o-min-device-pixel-ratio: 2 / 1), screen and (min-device-pixel-ratio: 1.3), screen and (min-resolution: 192dpi), screen and (min-resolution: 2dppx) {
                background-image: url('../../img/filetype-icons/generic@2x.png');
            }
            a {
                display: block;
                padding: 0;
                font-size: inherit;
                line-height: 1.3;
                text-decoration: underline;
            }
            .metadata {
                text-transform: uppercase !important;
                color: tint($primary-color, 50%);
            }
            &.type-ai { background-image: url('../../img/filetype-icons/ai.png'); }
            &.type-aif { background-image: url('../../img/filetype-icons/aif.png'); }
            &.type-aiff { background-image: url('../../img/filetype-icons/aiff.png'); }
            &.type-avi { background-image: url('../../img/filetype-icons/avi.png'); }
            &.type-bmp { background-image: url('../../img/filetype-icons/bmp.png'); }
            &.type-dmg { background-image: url('../../img/filetype-icons/dmg.png'); }
            &.type-doc { background-image: url('../../img/filetype-icons/doc.png'); }
            &.type-dwg { background-image: url('../../img/filetype-icons/dwg.png'); }
            &.type-eps { background-image: url('../../img/filetype-icons/eps.png'); }
            &.type-exe { background-image: url('../../img/filetype-icons/exe.png'); }
            &.type-gif { background-image: url('../../img/filetype-icons/gif.png'); }
            &.type-gz { background-image: url('../../img/filetype-icons/gz.png'); }
            &.type-htm { background-image: url('../../img/filetype-icons/htm.png'); }
            &.type-html { background-image: url('../../img/filetype-icons/html.png'); }
            &.type-indd { background-image: url('../../img/filetype-icons/indd.png'); }
            &.type-iso { background-image: url('../../img/filetype-icons/iso.png'); }
            &.type-jar { background-image: url('../../img/filetype-icons/jar.png'); }
            &.type-jpeg { background-image: url('../../img/filetype-icons/jpeg.png'); }
            &.type-jpg { background-image: url('../../img/filetype-icons/jpg.png'); }
            &.type-m4a { background-image: url('../../img/filetype-icons/m4a.png'); }
            &.type-m4v { background-image: url('../../img/filetype-icons/m4v.png'); }
            &.type-mid { background-image: url('../../img/filetype-icons/mid.png'); }
            &.type-mov { background-image: url('../../img/filetype-icons/mov.png'); }
            &.type-mp3 { background-image: url('../../img/filetype-icons/mp3.png'); }
            &.type-mp4 { background-image: url('../../img/filetype-icons/mp4.png'); }
            &.type-mpeg { background-image: url('../../img/filetype-icons/mpeg.png'); }
            &.type-mpg { background-image: url('../../img/filetype-icons/mpg.png'); }
            &.type-ogg { background-image: url('../../img/filetype-icons/ogg.png'); }
            &.type-pdf { background-image: url('../../img/filetype-icons/pdf.png'); }
            &.type-png { background-image: url('../../img/filetype-icons/png.png'); }
            &.type-pps { background-image: url('../../img/filetype-icons/pps.png'); }
            &.type-ppt { background-image: url('../../img/filetype-icons/ppt.png'); }
            &.type-ps { background-image: url('../../img/filetype-icons/ps.png'); }
            &.type-psd { background-image: url('../../img/filetype-icons/psd.png'); }
            &.type-rar { background-image: url('../../img/filetype-icons/rar.png'); }
            &.type-rtf { background-image: url('../../img/filetype-icons/rtf.png'); }
            &.type-sit { background-image: url('../../img/filetype-icons/sit.png'); }
            &.type-sitx { background-image: url('../../img/filetype-icons/sitx.png'); }
            &.type-tgz { background-image: url('../../img/filetype-icons/tgz.png'); }
            &.type-tif { background-image: url('../../img/filetype-icons/tif.png'); }
            &.type-tiff { background-image: url('../../img/filetype-icons/tif.png'); }
            &.type-txt { background-image: url('../../img/filetype-icons/txt.png'); }
            &.type-vob { background-image: url('../../img/filetype-icons/vob.png'); }
            &.type-wav { background-image: url('../../img/filetype-icons/wav.png'); }
            &.type-wma { background-image: url('../../img/filetype-icons/wma.png'); }
            &.type-wmv { background-image: url('../../img/filetype-icons/wmv.png'); }
            &.type-xls { background-image: url('../../img/filetype-icons/xls.png'); }
            &.type-zip { background-image: url('../../img/filetype-icons/zip.png'); }
            &.noicon { list-style-type: none; margin-left: none; padding-left: 0; background: none !important; }
            @media screen and (-webkit-min-device-pixel-ratio: 1.3), screen and (min--moz-device-pixel-ratio: 1.3), screen and (-o-min-device-pixel-ratio: 2 / 1), screen and (min-device-pixel-ratio: 1.3), screen and (min-resolution: 192dpi), screen and (min-resolution: 2dppx) {
                &.type-ai { background-image: url('../../img/filetype-icons/ai@2x.png'); }
                &.type-aif { background-image: url('../../img/filetype-icons/aif@2x.png'); }
                &.type-aiff { background-image: url('../../img/filetype-icons/aiff@2x.png'); }
                &.type-avi { background-image: url('../../img/filetype-icons/avi@2x.png'); }
                &.type-bmp { background-image: url('../../img/filetype-icons/bmp@2x.png'); }
                &.type-dmg { background-image: url('../../img/filetype-icons/dmg@2x.png'); }
                &.type-doc { background-image: url('../../img/filetype-icons/doc@2x.png'); }
                &.type-dwg { background-image: url('../../img/filetype-icons/dwg@2x.png'); }
                &.type-eps { background-image: url('../../img/filetype-icons/eps@2x.png'); }
                &.type-exe { background-image: url('../../img/filetype-icons/exe@2x.png'); }
                &.type-gif { background-image: url('../../img/filetype-icons/gif@2x.png'); }
                &.type-gz { background-image: url('../../img/filetype-icons/gz@2x.png'); }
                &.type-htm { background-image: url('../../img/filetype-icons/htm@2x.png'); }
                &.type-html { background-image: url('../../img/filetype-icons/html@2x.png'); }
                &.type-indd { background-image: url('../../img/filetype-icons/indd@2x.png'); }
                &.type-iso { background-image: url('../../img/filetype-icons/iso@2x.png'); }
                &.type-jar { background-image: url('../../img/filetype-icons/jar@2x.png'); }
                &.type-jpeg { background-image: url('../../img/filetype-icons/jpeg@2x.png'); }
                &.type-jpg { background-image: url('../../img/filetype-icons/jpg@2x.png'); }
                &.type-m4a { background-image: url('../../img/filetype-icons/m4a@2x.png'); }
                &.type-m4v { background-image: url('../../img/filetype-icons/m4v@2x.png'); }
                &.type-mid { background-image: url('../../img/filetype-icons/mid@2x.png'); }
                &.type-mov { background-image: url('../../img/filetype-icons/mov@2x.png'); }
                &.type-mp3 { background-image: url('../../img/filetype-icons/mp3@2x.png'); }
                &.type-mp4 { background-image: url('../../img/filetype-icons/mp4@2x.png'); }
                &.type-mpeg { background-image: url('../../img/filetype-icons/mpeg@2x.png'); }
                &.type-mpg { background-image: url('../../img/filetype-icons/mpg@2x.png'); }
                &.type-ogg { background-image: url('../../img/filetype-icons/ogg@2x.png'); }
                &.type-pdf { background-image: url('../../img/filetype-icons/pdf@2x.png'); }
                &.type-png { background-image: url('../../img/filetype-icons/png@2x.png'); }
                &.type-pps { background-image: url('../../img/filetype-icons/pps@2x.png'); }
                &.type-ppt { background-image: url('../../img/filetype-icons/ppt@2x.png'); }
                &.type-ps { background-image: url('../../img/filetype-icons/ps@2x.png'); }
                &.type-psd { background-image: url('../../img/filetype-icons/psd@2x.png'); }
                &.type-rar { background-image: url('../../img/filetype-icons/rar@2x.png'); }
                &.type-rtf { background-image: url('../../img/filetype-icons/rtf@2x.png'); }
                &.type-sit { background-image: url('../../img/filetype-icons/sit@2x.png'); }
                &.type-sitx { background-image: url('../../img/filetype-icons/sitx@2x.png'); }
                &.type-tgz { background-image: url('../../img/filetype-icons/tgz@2x.png'); }
                &.type-tif { background-image: url('../../img/filetype-icons/tif@2x.png'); }
                &.type-tiff { background-image: url('../../img/filetype-icons/tif@2x.png'); }
                &.type-txt { background-image: url('../../img/filetype-icons/txt@2x.png'); }
                &.type-vob { background-image: url('../../img/filetype-icons/vob@2x.png'); }
                &.type-wav { background-image: url('../../img/filetype-icons/wav@2x.png'); }
                &.type-wma { background-image: url('../../img/filetype-icons/wma@2x.png'); }
                &.type-wmv { background-image: url('../../img/filetype-icons/wmv@2x.png'); }
                &.type-xls { background-image: url('../../img/filetype-icons/xls@2x.png'); }
                &.type-zip { background-image: url('../../img/filetype-icons/zip@2x.png'); }
                &.noicon { list-style-type: none; margin-left: 0; padding-left: 0; background: none !important; }
            }
        }
    }
}
