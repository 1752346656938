.slider {
    position: relative;
    width: 100%;
    overflow: auto;
    ul.slider-list {
        list-style: none;
        margin-left: 0 !important;
        li.slider-list-entry {
            position: relative;
            display: block;
            float: left;
            padding: 0;
            min-height: 350px;
            background-repeat: no-repeat;
            background-position: center center;
            -webkit-background-size: cover;
            -moz-background-size: cover;
            -o-background-size: cover;
            background-size: cover;
            -webkit-transform: translate3d(0,0,0);
            -webkit-transform: translateZ(0px);
            .slider-content {
                float: right;
                padding: 0 $column-gutter;
                max-width: rem-calc(400);
                @media #{$small-only} {
                    position: absolute;
                    right: 0;
                    bottom: rem-calc(2);
                    padding: 0;
                    max-width: 100%;
                }
                h2,
                h3,
                p {
                    text-align: right;
                    font-family: "ITC Giovanni W01", serif;
                    font-size: 15px;
                    line-height: 20px;
                    background-color: $white;
                    color: $black;
                    border-top: 2px solid $black;
                    margin: rem-calc(3 0 0 0);
                    padding: rem-calc(4 8);
                    float: right;
                    clear: right;
                }
                h2 {
                    font-family: "Avenir W01", sans-serif!important;
                    font-weight: 900;
                    font-size: 13px;
                    line-height: 16px;
                    letter-spacing: 5.4px !important;
                    text-transform: uppercase;
                    margin-top: 0;
                    &:hover {
                        color: $white;
                        background-color: $black;
                    }
                    &:after {
                        padding-bottom: 0;
                        margin-bottom: 0;
                    }
                }
                h3 {
                    font-weight: 400;
                }
                p {
                    &.slider_abstract {
                        font-style: italic;
                    }
                    &.slider_info {
                    }
                }
            }
        }
    }
    .dots {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        text-align: center;
        line-height: inherit;
        margin-left: 0;
        padding-top: $paragraph-margin-bottom;
        margin-bottom: $column-gutter/2;
        li {
            display: inline-block;
            width: $paragraph-margin-bottom/2;
            height: $paragraph-margin-bottom/2;
            margin: 0 4px;
            text-indent: -999em;
            background-color: $white;
            -webkit-border-radius: $paragraph-margin-bottom/2;
            -moz-border-radius: $paragraph-margin-bottom/2;
            border-radius: $paragraph-margin-bottom/2;
            cursor: pointer;
            opacity: .5;
            &:hover,
            &.active {
                background: $blue;
                opacity: 1;
            }
            @media #{$small-only} {
                display: none;
            }
            @media #{$small-up} and (orientation: landscape) {
                display: none;
            }
        }
    }
    .slider-arrow {
        cursor: pointer;
        display: block;
        z-index: 999;
        position: absolute;
        height: 100%;
        color: transparentize($white, 0.5);
        &:hover {
            color: transparentize($white, 0.1);
        }
        i {
            text-shadow: none;
            font-size: rem-calc(60);
            @media #{$small-only} {
                font-size: rem-calc(15);
            }
            @media #{$small-up} and (orientation: landscape) {
                font-size: 3rem;
            }
            line-height: 1;
            position: relative;
            top: 50%;
        }
        &.prev {
            left: $column-gutter/2;
        }
        &.next {
            right: $column-gutter/2;
        }
    }
    .icon-arrow-bottom {
        padding-top: $column-gutter;
        padding-bottom: $column-gutter/2;
        text-shadow: none;
        font-size: rem-calc(60);
        display: block;
        @media #{$small-only} {
            font-size: rem-calc(15);
        }
        @media #{$small-up} and (orientation: landscape) {
            font-size: rem-calc(15);
        }
        cursor: pointer;
        color: transparentize($white, 0.5);
        &:hover {
            color: transparentize($white, 0.1);
        }
    }
    .icon-arrow-bottom-fixed {
        position: absolute;
        left: 0;
        right: 0;
        text-align: center;
        line-height: inherit;
        margin-left: 0;
    }
}