
/*
@font-face {font-family: "ITC Giovanni W01"; src: url("//db.onlinewebfonts.com/t/f343a5afb63641387c93db767bb5985f.eot"); src: url("//db.onlinewebfonts.com/t/f343a5afb63641387c93db767bb5985f.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/f343a5afb63641387c93db767bb5985f.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/f343a5afb63641387c93db767bb5985f.woff") format("woff"), url("//db.onlinewebfonts.com/t/f343a5afb63641387c93db767bb5985f.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/f343a5afb63641387c93db767bb5985f.svg#ITCGiovanniW01-Book") format("svg"); }
@font-face{
	font-family:"Avenir W01";
	src:url("Fonts/4f32268f-fd86-4960-b72c-4bb1ba75ec6f.eot?#iefix");
	src:url("Fonts/4f32268f-fd86-4960-b72c-4bb1ba75ec6f.eot?#iefix") format("eot"),url("Fonts/939cba03-5b40-4d01-9bc5-7589eca863db.woff") format("woff"),url("Fonts/849bc5b9-a2ff-4343-977b-26ba8bd24a60.ttf") format("truetype"),url("Fonts/f67fa3b5-c1d1-488f-8e60-a828b9ad56a4.svg#f67fa3b5-c1d1-488f-8e60-a828b9ad56a4") format("svg");
}
*/

.cc-window {
	opacity: 1;
	-webkit-transition: opacity 1s ease;
	transition: opacity 1s ease;
	position: fixed;
	overflow: hidden;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	z-index: 99999;
	-webkit-box-shadow: -1px -3px 9px -8px rgba(0,0,0,0.50);
	-moz-box-shadow: -1px -3px 9px -8px rgba(0,0,0,0.50);
	box-shadow: -1px -3px 9px -8px rgba(0,0,0,0.50);
	background: #ffffff;
	font-size: 15px;
}
.cc-window.cc-invisible {
	opacity: 0;
}
.cc-animate.cc-revoke {
	-webkit-transition: transform 1s ease;
	-webkit-transition: -webkit-transform 1s ease;
	transition: -webkit-transform 1s ease;
	transition: transform 1s ease;
	transition: transform 1s ease,-webkit-transform 1s ease;
}
.cc-animate.cc-revoke.cc-top {
	-webkit-transform: translateY(-2em);
	transform: translateY(-2em);
}
.cc-animate.cc-revoke.cc-bottom {
	-webkit-transform: translateY(2em);
	transform: translateY(2em);
}
.cc-animate.cc-revoke.cc-active.cc-top {
	-webkit-transform: translateY(0);
	transform: translateY(0);
}
.cc-animate.cc-revoke.cc-active.cc-bottom {
	-webkit-transform: translateY(0);
	transform: translateY(0);
}
.cc-revoke {
	display: none !important;
	&:hover {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		text-decoration: underline;
	}
	position: fixed;
	overflow: hidden;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	font-size: 16px;
	line-height: 1.5em;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	z-index: 9999;
	padding: .5em;
	cursor: pointer;
}
.cc-grower {
	max-height: 0;
	overflow: hidden;
	-webkit-transition: max-height 1s;
	transition: max-height 1s;
}
.cc-window.cc-static {
	position: static;
}
.cc-window.cc-floating {
	padding: 2em;
	max-width: 24em;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	.cc-compliance {
		-webkit-box-flex: 1;
		-ms-flex: 1 0 auto;
		flex: 1 0 auto;
	}
}
.cc-window.cc-banner {
	padding: $column-gutter/3 $column-gutter/2;
	box-sizing: border-box;
	width: 100%;
	min-height: 70px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.cc-window {
	.button {
		display: inline-table;
		border: 2px solid $primary-color;
		background: $primary-color;
		color: #ffffff;
		margin:0;
		text-transform: uppercase;
		padding: 0.5em 1.1em;
		font-weight: normal;
		cursor: pointer;
		text-rendering: optimizeLegibility;
		font-family: "Avenir W01",sans-serif;
		font-weight: bold;
		transition: background-color 300ms ease-out;
		&:hover,
		&:focus {
			background: #007af5;
			border: 2px solid #007af5;
		}
		&.secondary{
			background: #ffffff;
			color: $secondary-color;
			border: 2px solid #999999;
			margin-left: $column-gutter/3;
			&:hover,
			&:focus {
				color: #ffffff;
				background: #999999;
			}
		}
	}
}


.cc-revoke.cc-top {
	top: 0;
	left: 3em;
	border-bottom-left-radius: .5em;
	border-bottom-right-radius: .5em;
}
.cc-revoke.cc-bottom {
	bottom: 0;
	left: 3em;
	border-top-left-radius: .5em;
	border-top-right-radius: .5em;
}
.cc-revoke.cc-left {
	left: $column-gutter;
	right: unset;
}
.cc-revoke.cc-right {
	right: $column-gutter;
	left: unset;
}
.cc-top {
	top: $column-gutter/2;
}
.cc-left {
	left: $column-gutter/2;
}
.cc-right {
	right: $column-gutter/2;
}
.cc-bottom {
	bottom: $column-gutter/2;
}
.cc-floating {
	>.cc-link {
		margin-bottom: $column-gutter/2;
	}
	.cc-message {
		display: block;
		margin-bottom: $column-gutter/2;
	}
	.cc-compliance {
		>.cc-btn {
			-webkit-box-flex: 1;
			-ms-flex: 1;
			flex: 1;
		}
	}
}
.cc-banner.cc-top {
	left: 0;
	right: 0;
	top: 0;
}
.cc-banner.cc-bottom {
	left: 0;
	right: 0;
	bottom: 0;
}
.cc-banner {
	.cc-message {
		display: block;
		-webkit-box-flex: 1;
		-ms-flex: 1 1 auto;
		flex: 1 1 auto;
		max-width: 100%;
		line-height: 1.3;
		padding-right: $column-gutter;
		a {
			text-decoration: underline;
			color: inherit;
			&:hover {
				color: $primary-color;
			}
		}
		p {
			margin: 0;
			padding: 0;
			text-rendering: optimizeLegibility;
			font-family: "ITC Giovanni W01",sans-serif;
			font-weight: 400;
			font-size: 1.05em;
		}
	}
}
.cc-compliance {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-line-pack: justify;
	flex-direction: row-reverse;
	align-content: space-between;
}

.cc-floating.cc-theme-classic {
	padding: $column-gutter/2 $column-gutter/1.5;
}
.cc-floating.cc-type-info.cc-theme-classic {
	.cc-compliance {
		text-align: center;
		display: inline;
		-webkit-box-flex: 0;
		-ms-flex: none;
		flex: none;
	}
	.cc-btn {
		display: inline-block;
	}
}

.cc-theme-edgeless.cc-window {
	padding: 0;
}
.cc-floating.cc-theme-edgeless {
	.cc-message {
		margin:  $column-gutter;
		margin-bottom:  $column-gutter/2;
	}
	.cc-btn+.cc-btn {
		margin-left: 0;
	}
}
.cc-banner.cc-theme-edgeless {
	.cc-btn {
		margin: 0;
		padding:  $column-gutter/3  $column-gutter/2;
		height: 100%;
	}
	.cc-message {
		margin-left: $column-gutter/2;
	}
}
@media print {
	.cc-revoke {
		display: none;
	}
	.cc-window {
		display: none;
	}
}

@media screen and (max-width:768px) {
	.cc-window {
	
	}
	.cc-window.cc-top {
		top: 0;
	}
	.cc-window.cc-bottom {
		bottom: 0;
	}
	.cc-window.cc-banner {
		left: 0;
		right: 0;
		padding: $column-gutter/3.25;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: unset;
		-ms-flex-align: unset;
		align-items: unset;
		.cc-compliance {
			-webkit-box-flex: 1;
			-ms-flex: 1 1 auto;
			flex: 1 1 auto;
			align-items: center;
			justify-content: center;
		}
		.cc-message {
			padding-right: 0;
			p {
				text-align: center;
			}
		}
	}
	.cc-window.cc-floating {
		left: 0;
		right: 0;
		max-width: none;
	}
	.cc-window.cc-left {
		left: 0;
		right: 0;
	}
	.cc-window.cc-right {
		left: 0;
		right: 0;
	}
	.cc-window {
		.cc-message {
			margin-bottom: $column-gutter/3;
		}
	}
}
