.eventRow {
    margin-bottom: 2rem !important;
    background: #eee;

    a div {
        padding-left: 0;
    }
}

.noResultsMsg {
    text-align: center;
    padding-top: 1rem;
}

a.studio-link {
    color: black;

    &:hover {
        color: black;
    }
}

.studio-detail-container {
    padding: 15px;
    padding-bottom: 15px!important;

    .studioText {
        p:last-child {
            margin-bottom: 0px;
        }
    }
}

#ui-datepicker-div {
    display: none;
    background-color: #fff;
    box-shadow: 0 0.125rem 0.5rem rgba(0,0,0,0.1);
    margin-top: 0.25rem;
    border-radius: 0.5rem;
    padding: 0.5rem;
    width: 24.6rem;

    table.ui-datepicker-calendar {
        width: 23.6rem;
    }

    .ui-datepicker-calendar thead th {
        padding: 0.25rem 0;
        text-align: center;
        font-size: 0.9rem;
        font-weight: 400;
        color: #78909C;
    }

    .ui-datepicker-calendar tbody td {
        width: 2.5rem;
        text-align: center;
        padding: 0;

        a {
            display: block;
            border-radius: 0.25rem;
            line-height: 2rem;
            transition: 0.3s all;
            color: #546E7A;
            font-size: 1rem;
            text-decoration: none;

            &:hover {	
                background-color: #E0F2F1;
            }

            &.ui-state-active {
                background-color: #009688;
                color: white;
            }
        }
    }

    .ui-datepicker-header a.ui-corner-all {
        cursor: pointer;
        position: absolute;
        top: 0;
        width: 2rem;
        height: 2rem;
        margin: 0.5rem;
        border-radius: 0.25rem;
        transition: 0.3s all;

        &:hover {
            background-color: #ECEFF1;
        }
    }

    .ui-datepicker-header a.ui-datepicker-next {
    right: 0;
    background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIj48cGF0aCBmaWxsPSIjNDI0NzcwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjI4OCA2LjI5NkwzLjIwMiAyLjIxYS43MS43MSAwIDAgMSAuMDA3LS45OTljLjI4LS4yOC43MjUtLjI4Ljk5OS0uMDA3TDguODAzIDUuOGEuNjk1LjY5NSAwIDAgMSAuMjAyLjQ5Ni42OTUuNjk1IDAgMCAxLS4yMDIuNDk3bC00LjU5NSA0LjU5NWEuNzA0LjcwNCAwIDAgMS0xLS4wMDcuNzEuNzEgMCAwIDEtLjAwNi0uOTk5bDQuMDg2LTQuMDg2eiIvPjwvc3ZnPg==');
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: 50%;
	}

	.ui-datepicker-header a > span {
		display: none;
	}

	.ui-datepicker-title {
		text-align: center;
		line-height: 2rem;
		margin-bottom: 0.25rem;
		font-size: 1rem;
		font-weight: 500;
		padding-bottom: 0.25rem;
	}

	.ui-datepicker-week-col {
		color: #78909C;
		font-weight: 400;
		font-size: 0.75rem;
	}
}

.eventTitle {
    margin-top: 16px;
}

.eventText{
	overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    line-clamp: 4; 
    -webkit-box-orient: vertical;
}

.eventListImage{
	height: 385px;
    object-fit: cover;
    width: 385px;
}

.event-detail-text {
    padding-left: 18px;
    padding-bottom: 18px!important;
}

.jury-error {
    color: red;
    margin-bottom: 15px;
    display: block;
}

.eventComponentImg{
    height: 300px;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
}

.eventDetail{
    margin-bottom: 3px;
}

.hideOnMobile{
    @media #{$small-only} {
        display: none !important;        
    } 
}

div.eventDetail, li.download{
    a{
        color: $black;
        font-family: "ITC Giovanni W01" ;
        font-weight: 600;
        &:hover {
            color: #39f;
        }
    }    
}

li.download{
    a {
        font-weight: 400;
    }
}

a.showMore{
    color: $black;
    text-decoration: underline;
    font-family: "Avenir W01",sans-serif;
    font-weight: 600;
    display: block;
    margin-top: 1rem;
    
    &:hover {
        color: #39f;
    }
}

.boldDetails{
    font-weight: 600;
}

.studio-info-container {
    @media #{$medium-only} {
        height: 12.2em;
    }
    @media #{$small-only} {
        height: 12.2em;
    }
}

.juryLoginPage{
    input[type=text], input[type=password]{
        width: 32rem;
        @media #{$small-only} {
            width: 100%;
        }
    }   
    @media #{$medium-only} {
        min-height: 31rem;
    }
    @media #{$large-up} {
        min-height: 28rem;
    }     
    label{
        font-size: 15px;
    }
}

.floatRight{
    float: right;
}

.equalizeHeight{
    @media #{$medium-only} {
        min-height: 31rem;
    }
    @media #{$large-up} {
        min-height: 28rem;
    }     
}

.defaultLink{
    color: #000;
    font-family: 'ITC Giovanni W01';
    text-decoration: underline;
    font-weight: 400;
}
.defaultLink{
    &:hover{
        color: #39f;
    }
}
